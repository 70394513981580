<script setup lang="ts">
const config = useRuntimeConfig();

const landingUrl = computed(() => config.public.landingPageUrl);
const bridge128Url = computed(() => config.public.bridge128Url);
</script>

<template>
  <header class="bg-white py-5">
    <Container>
      <div class="flex flex-row justify-between items-center">
        <div class="hidden md:flex lg:w-auto items-center">
          <NuxtLink :to="landingUrl" class="logo">
            <NuxtIcon name="logo-with-text-dark" filled />
          </NuxtLink>
          <span class="text-brand-navy-600 text-xs ml-1">by Bridge128</span>
        </div>
        <NuxtLink :to="landingUrl" class="logo-mobile">
          <NuxtIcon name="logo-with-text-dark-mobile" filled />
        </NuxtLink>
        <nav
          class="flex-row-reverse md:flex-row flex items-center gap-2.5 md:gap-4"
        >
          <NuxtLink to="/login" class="inline-flex items-center">
            <span
              class="hidden md:inline text-brand-navy-600 text-xl font-medium mr-2"
            >
              Log In
            </span>
            <NuxtIcon name="user" filled class="user-icon" />
          </NuxtLink>
          <Button
            :to="bridge128Url"
            target="_blank"
            color="secondary"
            class-name="!px-9 !font-medium"
          >
            <span class="hidden md:inline">Learn on Bridge128</span>
            <span class="md:hidden">Learn</span>
          </Button>
          <GlossaryButton inverted />
        </nav>
      </div>
    </Container>
  </header>
</template>
<style lang="scss" scoped>
.logo {
  :deep(.nuxt-icon svg) {
    @apply w-[152px] h-12 mb-0;
  }
}
.logo-mobile {
  @apply md:hidden;

  :deep(.nuxt-icon svg) {
    @apply w-[133px] h-[42px] mb-0;
  }
}

:deep(.user-icon svg) {
  @apply w-10 h-10 mb-0;
}
</style>
